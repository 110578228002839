@import 'responsive/utils/mediaQueries.module';

.familyImage {
	position: relative;
	width: var(--family-small-width);
	aspect-ratio: 1;
	overflow: hidden;
	object-fit: cover;
	border-radius: 50%;

	@include onLargeOrGreater {
		width: var(--family-large-width);
	}
}
