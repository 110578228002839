@import 'mixins';

.timePart {
	font-family: 'Courier New', Courier, monospace;
	font-weight: 700;
}

.hidden {
	opacity: 0;
}
