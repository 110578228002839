@use 'texts';

.familyCardContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	text-decoration-line: none;

	.familyTextLabel {
		margin: 0;
		text-align: center;

		@extend %text-body-m;
	}
}
