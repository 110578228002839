@use 'texts';
@use 'breakpoints';
@import 'mixins';

.familyBanner {
	display: flex;
	flex-direction: column;
	text-decoration-line: none;
	gap: 16px;
	flex: 1;
}

.familyBannerTitle {
	margin: 0;
	overflow-wrap: break-word;
	hyphens: auto;

	@extend %text-inspirational-title-s;
}

.textContainer {
	margin: 0;
}

.imageContainer {
	position: relative;
	width: 100%;
	aspect-ratio: 5 / 7;

	img {
		object-fit: cover;
	}
}
