@use 'texts';
@use 'colors';

@mixin underline-text-default {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 4px;
}

@mixin underline-text-content {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: px2rem(1);
}

@mixin underline-text-inspirational {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: px2rem(8);
}

$inspirational-transition: color 0.5s cubic-bezier(0.3, 0.85, 0.5, 1);

.withIcon {
	display: flex;
	align-items: center;
	gap: 4px;
	width: fit-content;
}

.link {
	text-decoration: none;
	color: colors.$content-accent;

	svg {
		fill: currentcolor;
	}

	&:hover {
		color: colors.$content-accent-hover;
	}

	&.disabled {
		color: colors.$content-disabled;
		cursor: default;
	}

	&.active {
		color: colors.$content-accent-active;
	}

	&.inverse {
		color: colors.$content-accent-on-inverse;

		&:hover {
			color: colors.$content-accent-on-inverse-hover;
		}

		&.active {
			color: colors.$content-accent-on-inverse-active;
		}
	}

	&.unselected {
		color: colors.$content-accent-unselected;

		&:hover {
			color: colors.$content-accent-hover;
		}
	}

	&.default {
		@extend %text-body-m;

		&:hover,
		&.active {
			@include underline-text-default;
		}

		&.disabled {
			text-decoration: none;
		}
	}

	&.content {
		@extend %text-body-m-regular;
		@include underline-text-content;
	}

	&.content.small {
		@extend %text-body-s-regular;
	}

	&.inspirational {
		@extend %text-uppercase-m;

		transition: $inspirational-transition;

		&:hover,
		&.active {
			@include underline-text-inspirational;
		}

		&.disabled {
			text-decoration: none;
		}
	}

	&.inspirational.large {
		@extend %text-uppercase-l;
	}
}
